import Container from "react-bootstrap/Container";
import Card from "react-bootstrap/Card";
import Row from "react-bootstrap/Row";
import Col from "react-bootstrap/Col";
import Badge from "react-bootstrap/Badge";
import "../scss/blog.scss";

function BlogComponent() {
  const onItemClick = (url) => {
    console.log("asdasd" + url);
  };

  return (
    <Container fluid>
      <Container className="blog-title" data-aos="fade-up">
        <h1>Blog</h1>
        <p>Read about what's going here</p>
      </Container>
      <Container className="blog" fluid>
        <Container className="blog-list" data-aos="fade-up">
          <Card className="blog-item" onClick={() => onItemClick("/")}>
            <Row>
              <Col xs={12} md={3}>
                <p>2022/10/13</p>
                <Card.Img
                  variant="top"
                  src="https://pbs.twimg.com/profile_images/1057540719705939968/JPp_69uT_400x400.jpg"
                />
              </Col>
              <Col xs={12} md={9} className="mt-3">
                <h2>
                  Introduction to Wai-Wai - The Interactive Event Slideshow
                </h2>
                <p className="mt-3">
                  <Badge bg="secondary">Slideshow</Badge>{" "}
                  <Badge bg="secondary">Interactive</Badge>{" "}
                  <Badge bg="secondary">Event</Badge>{" "}
                </p>
              </Col>
            </Row>
          </Card>
          <Card className="blog-item" onClick={() => onItemClick("/")}>
            <Row>
              <Col xs={12} md={3}>
                <p>2022/10/13</p>
                <Card.Img
                  variant="top"
                  src="https://global-uploads.webflow.com/5dcc26d1cb881d7a98f0a292/615ada2cc842de0e204615ce_Showing%20images%E2%80%94Hero.jpg"
                />
              </Col>
              <Col xs={12} md={9} className="mt-3">
                <h2>
                  Introduction to Wai-Wai - The Interactive Event Slideshow
                </h2>
                <p className="mt-3">
                  <Badge bg="secondary">Slideshow</Badge>{" "}
                  <Badge bg="secondary">Interactive</Badge>{" "}
                  <Badge bg="secondary">Event</Badge>{" "}
                </p>
              </Col>
            </Row>
          </Card>
        </Container>
      </Container>
    </Container>
  );
}

export default BlogComponent;
