import Container from "react-bootstrap/Container";
import Card from "react-bootstrap/Card";
import Button from "react-bootstrap/Button";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faAndroid, faApple } from "@fortawesome/free-brands-svg-icons";
import "../scss/download_app.scss";

function DownloadAppComponent() {
  return (
    <Container className="download text-center" id="download" fluid>
      <div className="text-center" data-aos="fade-up">
        <h1 className="title">Download our app now</h1>
        <h5 className="subtitle text-black-50 mt-4">
          Choose the platform you prefer
        </h5>
      </div>
      <Card className="text-center text-white" data-aos="fade-up">
        <h1 className="card-title">Start completely free</h1>
        <div className="card-subtitle text-white mb-4">
          Create or join your first event inside the App
        </div>
        <Container className="text-center">
          <Button
            href="https://play.google.com/store/apps/details?id=com.fintasys.wai_wai_app"
            target="_blank"
            className="btn btn-primary btn-lg bg-white text-black"
          >
            <FontAwesomeIcon icon={faAndroid} fontSize="20" className="me-2" />
            <span className="fs-6 align-text-bottom">Download for Android</span>
          </Button>

          <Button
            href="#"
            target="_blank"
            className="btn btn-primary btn-lg bg-white text-black"
          >
            <FontAwesomeIcon icon={faApple} fontSize="20" className="me-2" />
            <span className="fs-6 align-text-bottom">Download for iOS</span>
          </Button>
        </Container>
      </Card>
    </Container>
  );
}

export default DownloadAppComponent;
