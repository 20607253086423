import { useState } from "react";
import Container from "react-bootstrap/Container";
import Nav from "react-bootstrap/Nav";
import Navbar from "react-bootstrap/Navbar";
import Offcanvas from "react-bootstrap/Offcanvas";
import "../scss/navbar.scss";

function NavBarComponent() {
  const [menuOpen, setMenuOpen] = useState(false);
  const toggleMenu = () => {
    setMenuOpen(!menuOpen);
  };
  const closeMenu = () => {
    setMenuOpen(false);
  };

  return (
    <Navbar bg="light" expand="lg" fixed="top">
      <Container>
        <Navbar.Brand href="/">
          <img
            alt=""
            src="./images/brand.png"
            width="48"
            height="48"
            className="d-inline-block align-center me-3"
          />
          Wai-Wai
        </Navbar.Brand>
        <Navbar.Toggle aria-controls="navbar" onClick={toggleMenu} />
        <Navbar.Offcanvas
          id="navbar"
          aria-labelledby="navbar"
          placement="end"
          restoreFocus={false}
          show={menuOpen}
          onHide={closeMenu}
        >
          <Offcanvas.Header closeButton>
            <Offcanvas.Title id="navbar">Menu</Offcanvas.Title>
          </Offcanvas.Header>
          <Offcanvas.Body>
            <Nav className="justify-content-end flex-grow-1 align-items-center">
              <Nav.Item>
                <a href="/#features" className="nav-link" onClick={closeMenu}>
                  Features
                </a>
              </Nav.Item>
              <Nav.Item>
                <a href="/#faq" className="nav-link" onClick={closeMenu}>
                  FAQ
                </a>
              </Nav.Item>
              <Nav.Item>
                <a href="/#pricing" className="nav-link" onClick={closeMenu}>
                  Pricing
                </a>
              </Nav.Item>
              <Nav.Item className="me-4 ms-4 mt-2 mb-2">
                <a
                  href="/app/signup"
                  variant="outline-secondary"
                  role="button"
                  className="btn btn-outline-secondary rounded-pill"
                >
                  SignUp
                </a>
              </Nav.Item>
              <Nav.Item className="mt-2 mb-2">
                <a
                  href="/app"
                  variant="primary"
                  role="button"
                  className="btn btn-primary text-white rounded-pill"
                >
                  Join Event
                </a>
              </Nav.Item>
            </Nav>
          </Offcanvas.Body>
        </Navbar.Offcanvas>
      </Container>
    </Navbar>
  );
}

export default NavBarComponent;
