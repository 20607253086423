import Container from "react-bootstrap/Container";
import Row from "react-bootstrap/Row";
import Col from "react-bootstrap/Col";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faCircleDown, faRocket } from "@fortawesome/free-solid-svg-icons";
import "../scss/header.scss";

function HeaderComponent() {
  return (
    <div className="header-background">
      <div id="shape1" className="shape" data-aos="zoom-in"></div>
      <div id="shape2" className="shape" data-aos="zoom-in"></div>
      <div id="shape3" className="shape" data-aos="zoom-in"></div>
      <Container className="header">
        <Row className="header-row">
          <Col className="header-column col-lg-6 col-12 text-center d-flex align-items-center">
            <div className="header-content text-start" data-aos="fade-up">
              <h1 className="text-white">
                Create your own interactive event Slideshow
              </h1>
              <div className="subtitle pb-4">
                Learn more about the features below, get started by downloading
                the app or create an event right way
              </div>
              <Row className="gx-2">
                <Col>
                  <a
                    className="btn btn-primary btn-lg bg-white text-black"
                    href="#download"
                    role="button"
                  >
                    <FontAwesomeIcon
                      icon={faCircleDown}
                      fontSize="20"
                      className="me-2"
                    />
                    <span className="fs-6 align-text-bottom">Download App</span>
                  </a>
                </Col>
                <Col>
                  <a
                    className="btn btn-primary btn-lg bg-white text-black"
                    href="/app/signup"
                    role="button"
                  >
                    <FontAwesomeIcon
                      icon={faRocket}
                      fontSize="20"
                      className="me-2"
                    />
                    <span className="fs-6 align-text-bottom">Create Event</span>
                  </a>
                </Col>
              </Row>
            </div>
          </Col>
          <Col
            className="header-column col-lg-6 col-12 text-center"
            data-aos="fade-up"
          >
            <div className="header-screenshot-wrapper">
              <div className="header-screenshot-second">
                <img alt="" src="./images/screen_watch.png" />
              </div>
            </div>
          </Col>
        </Row>
      </Container>
    </div>
  );
}

export default HeaderComponent;
