import React, { useEffect } from "react";
import NavBarComponent from "./components/navbar";

import "./scss/custom.scss";
import "./scss/app.scss";
import AOS from "aos";
import "aos/dist/aos.css";
import { Routes, Route } from "react-router-dom";
import HomeComponent from "./components/home";
import PrivacyComponent from "./components/privacy";
import TermsComponent from "./components/terms";
import FaqAllComponent from "./components/faq_all";
import BlogComponent from "./components/blog";
import FooterComponent from "./components/footer";

function App() {
  useEffect(() => {
    AOS.init({ duration: 2000, once: true, offset: 120 });
  }, []);

  return (
    <div className="App">
      <header>
        <NavBarComponent />
      </header>

      <main>
        <Routes>
          <Route path="/" element={<HomeComponent />} />
          <Route path="/privacy" element={<PrivacyComponent />} />
          <Route path="/terms" element={<TermsComponent />} />
          <Route path="/faq" element={<FaqAllComponent />} />
          <Route path="/blog" element={<BlogComponent />} />
        </Routes>
        <FooterComponent />
        <div className="hidden-iframe">
          <iframe
            src="https://www.wai-wai.app/app"
            width="0px"
            height="0px"
            title="iframe"
          ></iframe>
        </div>
      </main>
    </div>
  );
}

export default App;
