import Container from "react-bootstrap/Container";
import Row from "react-bootstrap/Row";
import Col from "react-bootstrap/Col";
import HeaderComponent from "./header";
import FAQComponent from "./faq";
import PricingComponent from "./pricing";
import DownloadAppComponent from "./download_app";
import { ReactSVG } from "react-svg";

function HomeComponent() {
  return (
    <Container className="g-0" fluid>
      <HeaderComponent />

      <Container className="marketing" id="features">
        <Row className="feature-row" data-aos="fade-up">
          <Col className="col-md-4 col-12 text-center">
            <ReactSVG src="./images/customize.svg" />
            <h2 className="fw-normal mt-3">Customize</h2>
            <p className="text-center">
              Choose between several options to customize the appearance of the
              slideshow to suite your taste.
            </p>
          </Col>
          <Col className="col-md-4 col-12 text-center">
            <ReactSVG src="./images/transitions.svg" />
            <h2 className="fw-normal mt-3">6 Unique Transitions</h2>
            <p>
              Select one of the many different unique transitions which should
              be used to animate between the photos.
            </p>
          </Col>
          <Col className="col-md-4 col-12 text-center">
            <ReactSVG src="./images/unlimited.svg" />
            <h2 className="fw-normal mt-3">Unlimited Plan</h2>
            <p>
              Unlimited Plan for bigger events like Weddings and events across
              multiple days.
            </p>
          </Col>
        </Row>

        <Row className="row featurette" data-aos="fade-up">
          <Col className="col-lg-5 col-12 order-lg-2 text-center">
            <ReactSVG src="./images/partying.svg" />
          </Col>
          <Col className="col-lg-7 col-12">
            <h2 className="featurette-heading fw-normal lh-1">
              Party together
            </h2>
            <p className="lead">
              Whether your are planing to do a small gathering with your friends
              or organizing your wedding party, create a slideshow based on your
              needs and customize it to suite your taste.
            </p>
          </Col>
        </Row>

        <Row className="row featurette" data-aos="fade-up">
          <Col className="col-lg-5 col-12 text-center">
            <ReactSVG src="./images/devices.svg" />
          </Col>
          <Col className="col-lg-7 col-12">
            <h2 className="featurette-heading fw-normal lh-1">
              Cast the event on a screen or projector
            </h2>
            <p className="lead">
              Use a smartphone or any device with internet connection to cast
              the slideshow on a screen or projector during the event. This is a
              great way to enternain your guests while taking a break. Learn
              more about how to do it in our FAQ!
            </p>
          </Col>
        </Row>

        <Row className="row featurette" data-aos="fade-up">
          <Col className="col-lg-5 col-12 order-lg-2 text-center">
            <ReactSVG src="./images/invite.svg" />
          </Col>
          <Col className="col-lg-7 col-12">
            <h2 className="featurette-heading fw-normal lh-1">
              Can't attend? Let them be part of it!
            </h2>
            <p className="lead">
              Some of your guests might not be able to attend the event, but
              they still can be part of it by watching the slideshow or
              participate by uploading photos themselves to send greetings and
              cheer.
            </p>
          </Col>
        </Row>

        <FAQComponent />
      </Container>

      <PricingComponent />

      <DownloadAppComponent />
    </Container>
  );
}

export default HomeComponent;
