import Container from "react-bootstrap/Container";
import Row from "react-bootstrap/Row";
import Col from "react-bootstrap/Col";
import Card from "react-bootstrap/Card";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faCheck, faXmark } from "@fortawesome/free-solid-svg-icons";
import "../scss/pricing.scss";

function PricingComponent() {
  return (
    <Container
      className="pricing"
      id="pricing"
      style={{ backgroundColor: "#f0f5f9" }}
      fluid
    >
      <div className="text-center" data-aos="fade-up">
        <h1>Choose the plan that's right for you</h1>
        <h5 className="mt-4">Check the differences below</h5>
      </div>
      <Row className="mt-5">
        <Col>
          <Card className="text-start" data-aos="fade-up">
            <Card.Header className="fs-2 text-primary fw-bold">
              Standard
            </Card.Header>
            <Card.Body>
              <Card.Title className="fs-2">Free</Card.Title>
              <div className="card-subtitle text-black-50">
                Best choice for nice gatherings with friends or smaller
                celerbrations on one day
              </div>
              <div className="card-content">
                <ul>
                  <li>
                    <div className="card-point positive">
                      <FontAwesomeIcon icon={faCheck} />
                    </div>
                    300 MB Storage
                  </li>
                  <li>
                    <div className="card-point positive">
                      <FontAwesomeIcon icon={faCheck} />
                    </div>
                    1 Day
                  </li>
                  <li>
                    <div className="card-point negative ">
                      <FontAwesomeIcon icon={faXmark} />
                    </div>
                    Ad free
                  </li>
                </ul>
              </div>
            </Card.Body>
          </Card>
          <Card className="text-start" data-aos="fade-up">
            <Card.Header className="fs-2 text-primary fw-bold">
              Unlimited
            </Card.Header>
            <Card.Body>
              <Card.Title className="fs-2">¥ 1000 / Day</Card.Title>

              <div className="card-subtitle text-black-50">
                Perfect for important moments like weddings and for events that
                last longer than one day
              </div>
              <div className="card-content">
                <ul>
                  <li>
                    <div className="card-point positive">
                      <FontAwesomeIcon icon={faCheck} />
                    </div>
                    Unlimited Storage
                  </li>
                  <li>
                    <div className="card-point positive">
                      <FontAwesomeIcon icon={faCheck} />
                    </div>
                    Multiple Days
                  </li>
                  <li>
                    <div className="card-point positive">
                      <FontAwesomeIcon icon={faCheck} />
                    </div>
                    Ad free
                  </li>
                </ul>
              </div>
            </Card.Body>
          </Card>
        </Col>
      </Row>
    </Container>
  );
}

export default PricingComponent;
