import Row from "react-bootstrap/Row";
import Col from "react-bootstrap/Col";
import Container from "react-bootstrap/Container";
import { HashLink as Link } from "react-router-hash-link";
import "../scss/footer.scss";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faTwitter } from "@fortawesome/free-brands-svg-icons";

function FooterComponent() {
  return (
    <footer id="footer" className="footer bg-dark text-white">
      <Container>
        <Row className="content">
          <Col className="col-lg-6 col-12">
            <div className="align-middle">
              <img
                alt=""
                src="./images/brand.png"
                width="48"
                height="48"
                className="d-inline me-3"
              />
              <h2 className="d-inline align-middle">Wai-Wai</h2>
            </div>
            <p className="brand-subtitle">
              Create your own interactive event Slideshow
            </p>
          </Col>
          <Col className="col-lg-2 col-4">
            <ul>
              <li>Wai-Wai</li>
              <li>
                <Link to="/#features">Features</Link>
              </li>
              <li>
                <Link to="/#faq">FAQ</Link>
              </li>
              <li>
                <Link to="/#pricing">Pricing</Link>
              </li>
            </ul>
          </Col>
          <Col className="col-lg-2 col-4">
            <ul>
              <li>Support</li>
              <li>
                <a href="/privacy">Privacy Policy</a>
              </li>
              <li>
                <a href="/terms">Terms of Use</a>
              </li>
            </ul>
          </Col>
          <Col className="col-lg-2 col-4">
            <ul>
              <li>Event</li>
              <li>
                <a href="/app">Join Event</a>
              </li>
              <li>
                <a href="/app">Create Event</a>
              </li>
            </ul>
          </Col>
        </Row>
        <hr />
        <Row className="sns">
          <Col className="col-4 text-muted">
            © 2022 Copyright by <span className="text-white">Stefan Humm</span>
          </Col>
          <Col className="col-4 text-center">
            Made with <span className="text-danger">♥</span> in Tokyo
          </Col>
          <Col className="col-4 text-end">
            <a className="text-white twitter" href="#!">
              <FontAwesomeIcon icon={faTwitter} size="2x" />
            </a>
          </Col>
        </Row>
      </Container>
    </footer>
  );
}

export default FooterComponent;
