import Container from "react-bootstrap/Container";
import Accordion from "react-bootstrap/Accordion";
import "../scss/faq.scss";

function FaqAllComponent() {
  return (
    <Container className="faq" data-aos="fade-up">
      <h1>Frequently Asked Questions</h1>
      <p>Find quickly what you like to know</p>
      <Accordion defaultActiveKey="-1" className="mb-3">
        <Accordion.Item eventKey="0">
          <Accordion.Header>
            How to stream the slideshow on my TV / Projector?
          </Accordion.Header>
          <Accordion.Body className="text-start">
            <p>
              Android and iOS devices have the functionality to cast their
              screen on a casting device. Such casting devices are for example
              Amazon Firestick or Google ChromeCast.
            </p>
            <p>
              For Android you can find more information{" "}
              <a
                target="_blank"
                rel="noreferrer"
                href="https://support.google.com/chromecast/answer/6059461?hl=en"
              >
                here
              </a>
            </p>
            <p>
              For iOS you can find more information{" "}
              <a
                target="_blank"
                rel="noreferrer"
                href="https://support.apple.com/en-us/HT204289"
              >
                here
              </a>
            </p>
          </Accordion.Body>
        </Accordion.Item>
      </Accordion>
      <Accordion defaultActiveKey="-1" className="mb-3">
        <Accordion.Item eventKey="0">
          <Accordion.Header>
            How can create a event over multiple days?
          </Accordion.Header>
          <Accordion.Body className="text-start">
            <p>
              Creating a event over multiple days is only available with our{" "}
              <strong>Unlimited Plan</strong>. For more information checkout the{" "}
              <a href="/#pricing">Pricing</a> section below.
            </p>
          </Accordion.Body>
        </Accordion.Item>
      </Accordion>
    </Container>
  );
}

export default FaqAllComponent;
